import ArrowIcon from 'components/icons/ArrowIcon';
import TankIcon from 'components/icons/TankIcon';
import { FaCircle } from 'react-icons/fa';
import { IoCalendar, IoContrastOutline, IoTimeSharp } from 'react-icons/io5';

export const COLUMN_HEADERS = [
  {
    key: 'tank_monitors.esn',
    name: 'monitors',
    icon: TankIcon,
    orderField: 'description',
  },
  {
    key: 'days_to_runout',
    name: 'days_to_runout',
    icon: IoCalendar,
    orderField: 'days_to_runout',
  },
  {
    name: 'alarms',
    orderField: null,
  },
  {
    key: 'tank_levels.inventory',
    name: 'inventory',
    icon: IoContrastOutline,
    transform: 'rotate(270deg)',
    orderField: 'tank_levels.inventory_ratio',
  },
  {
    name: 'ullage',
    icon: ArrowIcon,
    orderField: 'tank_levels.ullage',
  },
  {
    key: 'volume',
    name: 'capacity',
    icon: FaCircle,
    orderField: 'volume',
  },
  {
    name: 'last_reading_at',
    icon: IoTimeSharp,
    orderField: 'last_reading_at',
  },
];
